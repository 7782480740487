import axios from "axios";

const API_URL = `${process.env.REACT_APP_DOMAIN_NAME}/application/`;

class ApplicationService {
    application(
        name,
        emailOrPhone,
        description,
        subject,
        skill,
        urlSuffix) {
        return axios
            .post(API_URL + urlSuffix, {
                name,
                emailOrPhone,
                description,
                subject,
                skill
            })
            .then(response => {
                return response.data;
            });
    }

    applicationList(username, password) {
        return fetch(API_URL + "signin", {
            username,
            password
        })
            .then(response => {
                localStorage.setItem("user", JSON.stringify(response.data));
                return response.data;
            });
    }
}

export default new ApplicationService();
