import React from "react";
import './Footer.scss'
import payment_system from './payment_system.png'
import payment_system_sbp from './payment_system_sbp.png'
import {Link} from "react-router-dom";


export function Footer() {
    return (
        <div className='footer-container'>
            <section className='footer-subscription'>
               {/* <p className="footer-subscription-heading">
                    Первая строка с важной информацией
                </p>*/}
                <p className="footer-subscription-text">
                    «Господь наш! Прости нас и наших братьев, которые уверовали раньше нас!
                    Не насаждай в наших сердцах ненависти и зависти к тем, кто уверовал.
                    Господь наш! Воистину, Ты — Сострадательный, Милосердный» (59:10).
                </p>

            </section>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>МРОМ</h2>
                        <Link className='footer-size' to='about-us'>О нас</Link>
                        <Link className='footer-size' to='/news'>Новости</Link>
                        <Link className='footer-size' to='/contact-us'>Контакты</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>Полезное</h2>
                        <Link className='footer-size' to='/namaz-time'>Расписание намаза </Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>При мечети</h2>
                        <Link className='footer-size' to='/learn-arabic'>Учим арабскому</Link>
                        <Link className='footer-size' to='/volunteer'>Волонтерам</Link>
                        <Link className='footer-size' to='/ingush-community'>Община ингушей</Link>
                    </div>
                    <div className='footer-link-items'>
                        <h2>Инвесторам</h2>
                        {/*<Link className='footer-size' to={"#"}>Почему инвестор?</Link>*/}
                        {/*<Link className='footer-size' to={"#"}>На содержание мечети</Link>*/}
                        <Link className='footer-size' to={"/donation"}>Направить средства в МРОМ</Link>
                    </div>
                </div>
            </div>
            <div className="footer-line"/>
            <section className='footer-social-media'>
                <div className='footer-social-media-wrap'>
                    <div className='footer-social-icons'>
                        {/* <a href='/' className='social-icon-link facebook' aria-label='Facebook'>
                            <i className='fab fa-facebook'/>
                        </a>
                        <a href='https://www.instagram.com/zikr_mrom/'  className='social-icon-link instagram'
                           aria-label='Instagram'>
                            <i className='fab fa-instagram'/>
                        </a>*/}
                        <a  className='footer-payment-link' href='https://uniteller.ru'>
                        <img src={payment_system} className='footer-payment-icons' alt="Платежная система"  width="auto" height="20"/>
                        <img src={payment_system_sbp} className='footer-payment-icon-sbp' alt="Платежная система СБП" width="auto" height="35"/>
                        </a>
                    </div>
                    <a href={"/personal-data-usage-terms"} className='footer-website-rights'>МЕСТНАЯ РЕЛИГИОЗНАЯ ОРГАНИЗАЦИЯ МУСУЛЬМАН САНКТ-ПЕТЕРБУРГА
                        "ЗИКР"</a>

                </div>
            </section>
        </div>
    )
}
