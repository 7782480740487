import React from "react";

export function OrganizationDetailsComponent() {
    return (
        <>
            <div>
                <strong>«Местная религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>
                <br/>
                ОГРН: <strong>1077800030990</strong>
                <br/>
                ИНН/КПП: <strong>7838029346/780101001</strong>
                <br/>
                Адрес места нахождения: <strong>Россия, 199004, Санкт-Петербург г, линия 6-я В.О., д. 35, литер
                Б</strong>
                <br/>
                Банковские реквизиты:
                <br/>
                Номер банковского счёта: <strong>40703810497310000118</strong>
                <br/>
                Банк: <strong>ПАО "АК БАРС" БАНК</strong>
                <br/>
                БИК банка: <strong>049205805</strong>
                <br/>
                Номер корреспондентского счёта банка: <strong>30101810000000000805</strong>
                <br/>
                Председатель Правления: <strong>Хадзиев Магомед Абдулсаламович</strong>
            </div>
        </>
    );
}


