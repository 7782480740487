import React from "react";
import './NewsList.scss'
import CardItem from "./CardItem";


export default class NewsListContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            size: 4,
            from: 0,
        };
    }

    componentDidMount() {
        this.loadNews()
    }

    loadNews() {
        const {size, from, news} = this.state;
        const url = `${process.env.REACT_APP_DOMAIN_NAME}/news?from=${from}&size=${size}`;
        fetch(url)
            .then(res => res.json())
            .then(result =>
                this.setState({
                    news: [...news, ...result]
                })).catch(err=>{
            console.log(err)});
    }

    loadMore = () => {
        this.setState(prevState => ({
                from: this.state.from + 1,
                scrolling: true
            }),
            this.loadNews
        )
    }

    render() {
        return (
            <>
                <div className={'news-list-container'}>

                    {this.state.news.map(function (news, index) {
                        return <CardItem src={`${news.imagePath}`} heading={news.heading}
                                         subject={news.subject}
                                         path={'/news/' + news.id} key={index}/>
                    })}

                    <div className="center">
                        <button onClick={() => this.loadMore()}
                                className="news-list-button darkslategray far fa-plus-square fa-2x">
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

