import React from "react";

export function PaymentSecurityGuaranteesComponent() {
    return (
        <>
            <div className="privacy-policy-container privacy-policy-content">
                <text>
                    <h3>Гарантии безопасности платежа</h3>
                    <br/>
                    <strong>Уважаемый клиент!</strong>
                    <br/>
                    Вы можете оплатить свой заказ онлайн с помощью банковской карты через платежный сервис компании <a href="https://uniteller.ru" className="payment-security-guarantees-link">Uniteller</a>.
                    <br/>
                    <br/>
                    После подтверждения заказа Вы будете перенаправлены на защищенную платежную страницу Uniteller, где необходимо будет ввести данные для оплаты заказа. После успешной оплаты на указанную в форме оплаты электронную почту будет направлен электронный чек с информацией о заказе и данными по произведенной оплате.
                    <br/>
                    <br/>
                    <strong>Гарантии безопасности</strong>
                    <br/>
                    Безопасность процессинга Uniteller подтверждена сертификатом стандарта безопасности данных индустрии платежных карт PCI DSS. Надежность сервиса обеспечивается интеллектуальной системой мониторинга мошеннических операций, а также применением 3D Secure - современной технологией безопасности интернет-платежей.

                    Данные Вашей карты вводятся на специальной защищенной платежной странице. Передача информации в процессинговую компанию Uniteller происходит с применением технологии шифрования TLS. Дальнейшая передача информации осуществляется по закрытым банковским каналам, имеющим наивысший уровень надежности.

                    <br/>
                    <br/>
                    <strong>Uniteller не передает данные Вашей карты магазину и иным третьим лицам!</strong>
                    <br/>
                    <br/>
                    Если Ваша карта поддерживает технологию 3D Secure, для осуществления платежа, Вам необходимо будет пройти дополнительную проверку пользователя в банке-эмитенте (банк, который выпустил Вашу карту). Для этого Вы будете направлены на страницу банка, выдавшего карту. Вид проверки зависит от банка. Как правило, это дополнительный пароль, который отправляется в SMS, карта переменных кодов, либо другие способы.
                    <br/>
                    <br/>
                    <strong>«Возврат»</strong>
                    <br/>
                    Для возврата денежных средств Вам необходимо написать нам на почту <a href="mailto:zikr.spb@gmail.com" className="privacy-policy-link">zikr-spb@gmail.com</a>. Вам будет отправлен образец заявления на возврат денежных средств.

                    <br/>
                    <br/>
                    Срок рассмотрения заявки на возврат денежных средств составляет 10 дней.<br/>
                    Возврат денежных средств осуществляется на ту же банковскую карту, с которой производился платеж. Возврат денежных средств на карту осуществляется в срок от 3 до 30 банковских дней, в зависимости от Банка, которым была выпущена банковская карта.
                </text>
            </div>


        </>
    );
}


