import React, {Component} from "react";
import './Volunteer.scss';
import ApplicationService from "../../security/application-form.service";
import headerVolunteer from "./header-volunteer.png"

let phoneRegexp = "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$";
let emailRegexp = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"

const volunteerInitial = {
    name: "",
    emailOrPhone: "",
    description: "",
    skill: "OTHER",
    subject: "VOLUNTEER",
    websiteRequestNumber: "",
    nameError: "",
    emailOrPhoneError: "",
    descriptionError: "",
    applicationSuccess: false,
    applicationError: false,
}

export class Volunteer extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
        this.state = volunteerInitial;
    }


    validate = () => {
        let nameError = "";
        let emailOrPhoneError = "";
        let descriptionError = "";
        if (!this.state.name.trim()) {
            nameError = "Введите Ваше имя"
        }
        if (this.state.name.trim().length < 3 && !nameError) {
            nameError = "Введите Ваше имя. Более 3 символов"
        }
        if (!(this.state.emailOrPhone.match(phoneRegexp) || this.state.emailOrPhone.match(emailRegexp))) {
            emailOrPhoneError = "Введите email или номер телефона"
        }
        if (!this.state.description.trim()) {
            descriptionError = "Введите сообщение"
        }
        if (nameError || emailOrPhoneError || descriptionError) {
            this.setState({nameError, emailOrPhoneError, descriptionError});
            return false
        }
        return true
    }

    handleChange(event) {
        this.setState(({
                skill: event.target.value,
            }),
        )
    }

    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleApplicationWithCaptcha(e) {
        e.preventDefault()
        const isValid = this.validate()
        if (isValid) {
            ApplicationService.application(
                this.state.name,
                this.state.emailOrPhone,
                this.state.description,
                this.state.subject,
                this.state.skill,
                "").then(
                (response) => {
                    this.setState(volunteerInitial)
                    this.setState({
                        websiteRequestNumber: response,
                        applicationSuccess: true,
                    });
                },
                error => {
                    this.setState({
                        applicationError: true,
                    });
                });
        }
    }


    render() {
        return (
            <>
                <img alt={"Волотнерство"} src={headerVolunteer} width="100%" height="300" decoding="async" loading="lazy"/>
                <div className="volunteer-container">
                    <div className="volunteer-heading">
                        <span className="hg">Стать волонтером "Зикр"<br/> уже сейчас</span></div>
                    <div className="volunteer-title">Культура волонтерства</div>
                    <iframe className="volunteer-video" width="100%" height="600"  src="https://www.youtube.com/embed/qLGNj-xrgvY"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
                <div className="arabic-application-container">
                    <div className="arabic-application-article">Инвестируй в будущее. Вступай в нашу команду</div>

                    <div className="arabic-application-text">Заполни заявку, напиши о своих навыках</div>
                    <form id="volunteer-form" className="volunteer-form-horizontal" role="form"
                          onSubmit={this.handleApplicationWithCaptcha}>
                        {this.state.applicationSuccess ?
                            <div className="volunteer-response-alarm volunteer-success">Успех
                                <div>
                                    Номер заявки:<strong> {this.state.websiteRequestNumber} </strong>
                                </div>
                            </div> : null}
                        {this.state.applicationError ?
                            <div className="volunteer-response-alarm volunteer-error">Что-то пошло не так
                                <div>
                                    Пожалуйста, напишите нам на почту.
                                </div>
                            </div> : null}
                        <label htmlFor="name" className="volunteer-input">
                            <input type="text" className="volunteer-form-control" id="name" name="name"
                                   placeholder="&nbsp;"
                                   onChange={this.onInputChange} value={this.state.name}/>
                            <span className="volunteer-label">Как к Вам обращаться?</span>
                            <span className="volunteer-focus-bg"/>
                        </label>
                        {this.state.nameError ?
                            <div className="volunteer-alarm">{this.state.nameError}</div> : null}
                        <label htmlFor="emailOrPhone" className="volunteer-input">
                            <input type="text" className="volunteer-form-control" id="emailOrPhone"
                                   name="emailOrPhone"
                                   placeholder="&nbsp;"
                                   onChange={this.onInputChange} value={this.state.emailOrPhone}/>
                            <span className="volunteer-label">Email или телефон</span>
                            <span className="volunteer-focus-bg"/>
                        </label>
                        {this.state.emailOrPhoneError ?
                            <div className="volunteer-alarm">{this.state.emailOrPhoneError}</div> : null}
                        <label htmlFor="skill">
                            <select id="skill" className="arabic-learn-select"
                                    onChange={(e) => this.handleChange(e)}>
                                <option defaultValue="OTHER">Другое</option>
                                <option value="BUILDING">Строительство</option>
                                <option value="ARABIC_LEARN">Обучение арабскому языку</option>
                                <option value="EVENT_HELP">Помощь в мероприятиях</option>
                                <option value="SITE_MANAGEMENT">Управление соц. сетями</option>
                                <option value="VIDEO_RECORDING">Запись/монтаж видео</option>
                            </select>
                        </label>
                        <label htmlFor="description" className="volunteer-input">
                            <textarea className="volunteer-textarea volunteer-form-control" id="description"
                                      name="description"
                                      placeholder="&nbsp;"
                                      onChange={this.onInputChange} value={this.state.description}/>
                            <span className="volunteer-label volunteer-description-label">Опишите, что вы умеете?</span>
                            <span className="volunteer-focus-bg"/>
                        </label>
                        {this.state.descriptionError ?
                            <div className="volunteer-alarm ">{this.state.descriptionError}</div> : null}
                        <button type="submit" className="volunteer-send-button" id="submit"
                                value="Отправить">
                            <div className="volunteer-alt-send-button">
                                <i className="fa fa-paper-plane"></i><span
                                className="volunteer-send-text">Отправить</span>
                            </div>
                        </button>
                    </form>
                </div>
            </>
        );
    }
}


