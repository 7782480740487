import React, {useState} from 'react'
import {MenuItem} from './MenuItem'
import {Link} from 'react-router-dom'
import './Dropdown.scss'


export default function Dropdown() {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    return (<>
            <ul onClick={handleClick}
                className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {MenuItem.map((item, index) => {
                    return (
                        <li key={index}>
                            <Link className={item.className} to={item.path} onClick={() =>
                                setClick(false)}>{item.title}</Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
