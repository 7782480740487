import React from "react";

export function PrivacyPolicyComponent() {
    return (
        <>
            <div className="privacy-policy-container privacy-policy-content">
                <text> <h3>Согласие на обработку персональных данных</h3>
                    <br/>
                    Пользователь, оставляя заявку, оформляя подписку, комментарий, запрос на обратную связь,
                    регистрируясь
                    либо совершая иные действия, связанные с внесением своих персональных данных на
                    интернет-сайте <a href="/" className="privacy-policy-link">zikr-spb.ru</a>, принимает настоящее Согласие на обработку персональных данных (далее –
                    Согласие),
                    размещенное по адресу <a href="/personal-data-usage-terms" className="privacy-policy-link">zikr-spb.ru/personal-data-usage-terms</a>.
                    <br/>
                    Принятием Согласия является подтверждение факта согласия Пользователя со всеми пунктами
                    Согласия.
                    <br/>
                    Пользователь дает свое согласие организации <strong>«Местная
                        религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>, которой принадлежит сайт <a href="/" className="privacy-policy-link">zikr-spb.ru</a> на обработку своих персональных данных со
                    следующими условиями:
                    <br/>
                    Пользователь дает согласие на обработку своих персональных данных, как без использования средств
                    автоматизации, так и с их использованием.
                    <br/>
                    Согласие дается на обработку следующих персональных данных (не являющимися специальными или
                    биометрическими):
                    <br/>
                    • фамилия, имя, отчество;
                    <br/>
                    • адрес(а) электронной почты;
                    <br/>
                    • иные данные, предоставляемые Пользователем.
                    <br/>
                    Персональные данные пользователя не являются общедоступными.
                    <br/>
                    1. Целью обработки персональных данных является предоставление полного доступа к функционалу
                    сайта <a href="/" className="privacy-policy-link">zikr-spb.ru</a>.
                    <br/>
                    2. Основанием для сбора, обработки и хранения персональных данных являются:
                    <br/>
                    • Ст. 23, 24 Конституции Российской Федерации;
                    <br/>
                    • Ст. 2, 5, 6, 7, 9, 18–22 Федерального закона от 27.07.06 года №152-ФЗ «О персональных данных»;
                    <br/>
                    • Ст. 18 Федерального закона от 13.03.06 года № 38-ФЗ «О рекламе»;
                    <br/>
                    • Устав организации <strong>«Местная религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>;
                    <br/>
                    • Политика обработки персональных данных.
                    <br/>
                    3. В ходе обработки с персональными данными будут совершены следующие действия с персональными
                    данными:
                    сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение),
                    извлечение,
                    использование, передача (распространение, предоставление, доступ), обезличивание, блокирование,
                    удаление, уничтожение.
                    <br/>
                    4. Передача персональных данных, скрытых для общего просмотра, третьим лицам не осуществляется,
                    за
                    исключением случаев, предусмотренных законодательством Российской Федерации.
                    <br/>
                    5. Пользователь подтверждает, что указанные им персональные данные принадлежат лично ему.
                    <br/>
                    6. Персональные данные хранятся и обрабатываются до момента ликвидации организации <strong>«Местная
                        религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>. Хранение персональных данных осуществляется
                    согласно
                    Федеральному закону №125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым
                    актам в
                    области архивного дела и архивного хранения.
                    <br/>
                    7. Пользователь согласен на получение информационных сообщений с сайта <a href="/" className="privacy-policy-link">zikr-spb.ru</a>.
                    Персональные
                    данные обрабатываются до отписки Пользователя от получения информационных сообщений.
                    <br/>
                    8. Согласие может быть отозвано Пользователем либо его законным представителем, путем
                    направления Отзыва
                    согласия на электронную почту – <a href="mailto:zikr.spb@gmail.com" className="privacy-policy-link">zikr-spb@gmail.com</a> с пометкой «Отзыв согласия на обработку
                    персональных
                    данных». В случае отзыва Пользователем согласия на обработку персональных данных организация
                    <strong>«Местная религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong> вправе продолжить обработку
                    персональных
                    данных без согласия Пользователя при наличии оснований, указанных в пунктах 2 - 11 части 1
                    статьи 6,
                    части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от
                    27.07.2006
                    г. Удаление персональных данных влечет невозможность доступа к полной версии функционала
                    сайта <a href="/" className="privacy-policy-link">zikr-spb.ru</a>.
                    <br/>
                    9. Настоящее Согласие является бессрочным, и действует все время до момента прекращения
                    обработки
                    персональных данных, указанных в п.7 и п.8 данного Согласия.
                    <br/>
                    10. Место нахождения организации <strong>«Местная религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong> в
                    соответствии с учредительными документами: <strong>Россия, 199004, Санкт-Петербург г, линия 6-я В.О., д.
                    35,
                    литер Б</strong>
                </text>
            </div>


        </>
    );
}


