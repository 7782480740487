import React, {Component} from 'react'
import './Signin.css'
import AuthService from '../components/security/auth.service.js'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

let passwordRegexp = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#\$%^&+=])(?=\\S+\$).{8,}\$"

const initialState = {
    username: "",
    password: "",
    loading: false,
    message: "",
    usernameError: "",
    passwordError: "",
}


export default class SignIn extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.state = initialState
    }

    validate = () => {
        let usernameError = "";
        let passwordError = "";
        if (!this.state.username.trim()) {
            usernameError = "Введите Ваш логин"
        }
        if (this.state.password.length < 8) {
            passwordError = "Пароль должен содержать от 8 символов"
        } else if (!(this.state.password.match(passwordRegexp))) {
            passwordError = "Пароль должен содержать большую, маленькую букву, цифру и символ"
        }
        if (usernameError || passwordError) {
            this.setState({usernameError, passwordError});
            return false
        }
        return true
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault()
        this.setState({
            message: "",
            loading: true
        });
        const isValid = this.validate()
        if (isValid) {
            AuthService.login(this.state.username, this.state.password).then(
                () => {
                    this.props.history.push("/admin/home");
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (resMessage === "Request failed with status code 401") {
                        this.setState(initialState)
                        this.setState({
                            // loading: false,
                            message: "Не правильный логин или пароль"
                        });
                    }
                });
        }
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    render() {
        return (
            <>
                <div className='signInContainer'>
                    <div className='form-wrap'>
                        <div className='icon'>
                            <div className='form-content'>
                                <div className='form'>
                                    <div className='formH1'>Войдите в систему</div>
                                    <h6 style={{color: 'pink'}}>  {this.state.message}</h6>
                                    <Form onSubmit={this.handleLogin}
                                          ref={c => {
                                              this.form = c;
                                          }}>
                                        <br/>
                                        <div className="form-label">
                                            <label htmlFor="username">Логин</label>
                                            <Input
                                                type="text"
                                                className="form-input"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this.onChangeUsername}
                                                /*    validations={[required]}*/
                                            />
                                            {this.state.usernameError ?
                                                <div style={{color: "red"}}>{this.state.usernameError}</div> : null}
                                        </div>
                                        <div className="form-label">
                                            <label htmlFor="password">Пароль</label>
                                            <Input
                                                type="password"
                                                className="form-input"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChangePassword}
                                                /*       validations={[required]}*/
                                            />
                                            {this.state.passwordError ?
                                                <div style={{color: "red"}}>{this.state.passwordError}</div> : null}

                                        </div>
                                        <button className='form-button' type="submit">Войти</button>
                                    </Form>
                                    <div className='text'>Забыл пароль</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
