import React from "react";
import './ApplicationList.scss'
import authHeader from "../../security/auth-header";


export default class ApplicationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            applicationType: "CONTACT_US",
            size: 3,
            from: 0,
        };
    }

    componentDidMount() {
        this.loadApplications()
    }

    loadApplications() {
        const {size, from, applicationType, applications} = this.state;
        const url = `${process.env.REACT_APP_DOMAIN_NAME}/application/list?applicationType=${applicationType}&from=${from}&size=${size}`;
        fetch(url, {
            headers: authHeader()
        })
            .then(res => res.json())
            .then(result =>
                this.setState({
                    applications: [...applications, ...result]
                }), error => {});
    }

    loadMore = () => {
        this.setState(() => ({
                from: this.state.from + 1,
                scrolling: true
            }),
            this.loadApplications
        )
    }

    handleChange(event) {
        this.setState(({
                applicationType: event.target.value,
                applications: [],
                from: 0
            }),
            this.loadApplications
        )
    }

    render() {
        return (
            <>
                <div className='application_container'>
                    <div className="dropdown-wrapper">
                        <select className="dropdown" onChange={(e) => this.handleChange(e)}>
                            <option defaultValue="CONTACT_US">Имаму</option>
                            <option value="VOLUNTEER">Волонтеры</option>
                            <option value="ARABIC_LEARN">Арабский</option>
                        </select>
                    </div>
                    <table className="application_table">
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>Номер заявки</th>
                            <th>Имя</th>
                            <th>Email или Телефон</th>
                            <th>Описание</th>
                            <th>Дата заявки</th>
                            {/*<th>Навык</th>*/}
                        </tr>
                        </thead>
                        <div className="at_body">
                            {this.state.applications.map(function (applications, index) {
                                return <tr>
                                    <td data-label="№">{index + 1}</td>
                                    <td data-label="Номер заявки">{applications.websiteRequestNumber}</td>
                                    <td data-label="Имя">{applications.name}</td>
                                    <td data-label="Email или Телефон">{applications.emailOrPhone}</td>
                                    <td data-label="Описание"><br/>{applications.description}</td>
                                    <td data-label="Дата заявки">{applications.date}</td>
                                    {/*<td>{applications.skill}</td>*/}
                                </tr>
                            })}
                        </div>
                    </table>

                    <div className="load-more-button">
                        <button onClick={() => this.loadMore()}
                                className=" darkslategray far fa-plus-square fa-2x">
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

