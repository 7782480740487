import React, {Component} from "react";
import './OrganizationDetails.scss';
import {OrganizationDetailsComponent} from "./OrganizationDetailsComponent";

export class OrganizationDetails extends Component {
    constructor(props) {
        super(props);
        // this.onInputChange = this.onInputChange.bind(this);
        // this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
    }

    render() {
        return <div className="organization-details-container organization-details-content">
            <div>
            <h3>Реквизиты МРОМ "Зикр"</h3>
            <br/>
            <br/>
            <OrganizationDetailsComponent/>
            </div>
        </div>
    }
}


