export const MenuItem = [
    {
        title: 'Ингушская община',
        path: '/ingush-community',
        className: 'dropdown-link'
    },
    {
        title: 'Учим арабскому',
        path: '/learn-arabic',
        className: 'dropdown-link'
    },
    {
        title: 'Волонтерам',
        path: '/volunteer',
        className: 'dropdown-link'
    }
]
