import React from 'react';
import './Button.scss'
import {Link} from 'react-scroll'

const STYLES = ['btn-primary', 'btn-outline']

const SIZES = ['btn-medium', 'btn-large'];

export const Button = ({
                           children,
                           type,
                           onClick,
                           buttonStyle,
                           buttonSize
                       }) => {
    const checkButtonStyle = STYLES.includes(buttonSize) ?
        buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
        <Link to='news-list-scroll-point' spy={true} smooth={true}>
            <button className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                    onClick={onClick}
                    type={type}>
                {children}
            </button>
        </Link>
    )
};
