import React from "react";
import './NewsList.scss'


export default function NewsListTitle(props) {
    return (
        <>
            <div className="news-list-title">
                <div className="news-list-category-title">{props.value}</div>
            </div>
        </>
    )
}

