import React from "react";
import {Link} from "react-router-dom";
import './CardItem.scss'

export default function CardItem(props) {
    return (
        <>
            <div className="news-list-item item_image-mob">
                <div className="news-list-item-wrap">
                    <div className="news-list-item-bottom">
                        <span className="news-list-item-category">{props.heading}</span>
                    </div>
                    <Link target='_blank' className="news-list-item-link" to={props.path}>
                            <span className="news-list-item-title-wrap">
                                <span
                                    className="news-list-item-title">{props.subject}</span>
                            </span>
                        <span className="news-list-item-image-block">
                                            <img
                                                src={'/' + props.src}
                                                className="news-list-item-image" width="1200" height="200"
                                                decoding="async" alt="" loading="lazy"/>
                            </span>
                    </Link>
                </div>
            </div>
        </>
    )
}
