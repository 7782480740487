import React, {Component} from "react";
import './IngushCommunity.scss';

import oldBuilding from "./oldBuildings.jpeg";
import imam from "../learnArabic/imam.png";

export class IngushCommunity extends Component {

    render() {
        return (
            <>
                <div className="ing-container">
                    <img width="100%" height="90%" src={oldBuilding}  decoding="async" loading="lazy"alt="Старые ингушские сооружения"/>
                    <div className="ing-title">Сообщество ингушей</div>
                    <div className="ing-text-area">
                        Мы рады пригласить вас на ингушские мероприятия. Пожалуйста, следите за новостями на
                        нашем сайте или
                        мессенджерах
                        <br/>
                        Чтобы узнать про ингушские мероприятия, можно перейти
                        к <a className="ing-schedule-link" href="/news"> новостям </a>
                    </div>
                    <iframe className="ing-video" width="100%" height="600" src="https://www.youtube.com/embed/eqvcSySW6yk?start=3"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                    <div className="ing-imam-speech-container">
                        <img className="ing-imam-image" decoding="async" loading="lazy" width="7%" height="auto" src={imam}
                             alt="Имам мечети"/>
                        <div>
                            <p className="ing-imam-quote">"Представители ингушского народа вносят огромный вклад в поддержку и развитие нашей организации.
                                Благодаря совместным усилиям мы доносим исламские принципы и ценности до жителей города Санкт-Петербург"
                            </p>
                            <strong>Магомед Хадзиев </strong>
                            <br/>
                            имам, председатель МРОМ "Зикр"

                        </div>
                    </div>
                </div>
            </>
        );
    }
}


