import React, {Component} from 'react'
import './Signin.css'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "./security/auth.service";
import AdminService from "./security/admin.service";


let passwordRegexp = "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#\$%^&+=])(?=\\S+\$).{8,}\$"

const initialState = {
    username: "",
    password: "",
    fio: "",
    role: "ADMIN",
    usernameError: "",
    passwordError: "",
    fioError: "",
    multipartImage: null,
    imageError: "",

};

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.handleRegistration = this.handleRegistration.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = initialState
    }

    validate = () => {
        let usernameError = "";
        let passwordError = "";
        let fioError = "";
        let imageError = "";
        if (!this.state.username.trim()) {
            usernameError = "Введите Ваш логин"
        }
        if (this.state.username.trim() < 3 || this.state.username.trim() > 50) {
            usernameError = "Введите от 3 до 20 символов"
        }
        if (!this.state.fio.trim()) {
            fioError = "Введите Фамилию Имя Отчество"
        }
        if (this.state.fio.trim() < 3 || this.state.fio.trim() > 50) {
            fioError = "Введите от 3 до 50 символов"
        }
        if (this.state.password.length < 8) {
            passwordError = "Пароль должен содержать от 8 символов"
        } else if (!(this.state.password.match(passwordRegexp))) {
            passwordError = "Пароль должен содержать большую, маленькую букву, цифру и символ"
        }
        if (this.state.multipartImage && this.state.multipartImage.size > 5000000) {
            imageError = "Размер файла превышает 5 мб"
        }
        if (usernameError || passwordError || fioError || imageError) {
            this.setState({usernameError, passwordError, fioError, imageError});
            return false
        }
        return true
    }


    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChange(event) {
        this.setState(({
                role: event.target.value,
            }),
        )
    }

    componentDidMount() {
        AdminService.hasPermission().then(
            response => {
                this.setState({
                    content: response.data
                });
            },
            error => {
                this.props.history.push("/");
                window.location.reload();
            }
        );
    }

    fileSelectedHandler = event => {
        this.setState({
                selectedFile: event.target.files[0]
            }
        )
    };
    handleImage = (event) => {
        console.log(event.target.files[0])
        this.setState({multipartImage: event.target.files[0]});
    };

    render() {
        return (
            <>
                <div className='container'>
                    <div className='form-wrap'>
                        <div className='icon'>
                            <div className='form-content'>
                                <div className='form'>
                                    <div className='formH1'>Регистрация</div>
                                    <Form onSubmit={this.handleRegistration}
                                          ref={c => {
                                              this.form = c;
                                          }}>
                                        <div className="form-label">
                                            <label htmlFor="username">Логин</label>
                                            <Input
                                                type="text"
                                                className="form-input"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this.onInputChange}
                                            />
                                            {this.state.usernameError ?
                                                <div style={{color: "red"}}>{this.state.usernameError}</div> : null}

                                        </div>

                                        <div className="form-label">
                                            <label htmlFor="password">Пароль</label>
                                            <Input
                                                type="password"
                                                className="form-input"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onInputChange}
                                            />
                                            {this.state.passwordError ?
                                                <div style={{color: "red"}}>{this.state.passwordError}</div> : null}

                                        </div>
                                        <div className="form-label">
                                            <label htmlFor="text">Фамилия Имя Отчество</label>
                                            <Input
                                                type="text"
                                                className="form-input"
                                                name="fio"
                                                value={this.state.fio}
                                                onChange={this.onInputChange}
                                            />
                                            {this.state.fioError ?
                                                <div style={{color: "red"}}>{this.state.fioError}</div> : null}

                                        </div>
                                        <div className="form-label">
                                            <input style={{display: 'none'}}
                                                   type='file'
                                                   onChange={this.fileSelectedHandler}
                                                   ref={fileInput => this.fileInput = fileInput}/>
                                            <input type='file' name="multipartImage" onChange={this.handleImage}/>
                                            {this.state.imageError ?
                                                <div style={{color: "red"}}>{this.state.imageError}</div> : null}
                                        </div>


                                        <label htmlFor="role">Роль</label>
                                        <select id="role" onChange={(e) => this.handleChange(e)}>
                                            <option defaultValue="ADMIN">Администратор</option>
                                            <option value="USER">Пользователь</option>
                                        </select>
                                        <button className='form-button' type="submit">Регистрация</button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    handleRegistration(e) {
        e.preventDefault()
        const isValid = this.validate()
        if (isValid) {
            let data = new FormData();
            data.append("multipartImage", this.state.multipartImage);
            data.set("username", this.state.username);
            data.set("password", this.state.password);
            data.set("fio", this.state.fio);
            data.set("role", this.state.role);

            AuthService.register(data).then(
                () => {
                    this.props.history.push("/admin/login");
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        }
    }
}
