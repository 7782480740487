import React from 'react'
import {Button} from "./Button";
import './HeroSection.scss'

export function HeroSection() {
    return (
        <div className='hero-container services'>
            <div className='hero-btn-height'>
                <Button buttonSize='btn-primary'
                        buttonStyle='btn-large'>Новости сейчас</Button>
            </div>
        </div>
    )
}
