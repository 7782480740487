import React, {Component} from "react";
import './ContactUs.scss';
import ApplicationService from "../../security/application-form.service";
import TypeWriter from "typewriter-effect"

let phoneRegexp = "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$";
let emailRegexp = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"

const contactUsInitial = {
    name: "",
    emailOrPhone: "",
    description: "",
    skill: "UNDEFINED",
    subject: "CONTACT_US",
    websiteRequestNumber: "",
    nameError: "",
    emailOrPhoneError: "",
    descriptionError: "",
    mosqueAddress: "г. Санкт-Петербург 6-я лин. В.О. 35Б. 199178",
    contact: "г. Санкт-Петербург 6-я лин. В.О. 35Б. 199178",
    applicationSuccess: false,
    applicationError: false,
}

export class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
        this.state = contactUsInitial
    }

    validate = () => {
        let nameError = "";
        let emailOrPhoneError = "";
        let descriptionError = "";
        if (!this.state.name.trim()) {
            nameError = "Введите Ваше имя"
        }
        if (this.state.name.trim().length < 3 && !nameError) {
            nameError = "Введите Ваше имя. Более 3 символов"
        }
        if (!this.state.emailOrPhone.trim() || !(this.state.emailOrPhone.match(phoneRegexp) || this.state.emailOrPhone.match(emailRegexp))) {
            emailOrPhoneError = "Введите email или номер телефона"
        }
        if (!this.state.description.trim()) {
            descriptionError = "Введите сообщение"
        }
        if (nameError || emailOrPhoneError || descriptionError) {
            this.setState({nameError, emailOrPhoneError, descriptionError});
            return false
        }
        return true
    }


    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleApplicationWithCaptcha(e) {
        e.preventDefault()
        const isValid = this.validate()
        if (isValid) {
            ApplicationService.application(
                this.state.name,
                this.state.emailOrPhone,
                this.state.description,
                this.state.subject,
                this.state.skill,
                "").then(
                (response) => {
                    this.setState(contactUsInitial)
                    this.setState({
                        websiteRequestNumber: response,
                        applicationSuccess: true,
                    });
                },
                error => {
                    this.setState({
                        applicationError: true,
                    });
                });
        }
    }


    render() {
        return (
            <>
                <section id="contact">
                    <div className="contact-type-writer">
                        <TypeWriter onInit={typewriter => {
                            typewriter.typeString("Напишите Ваш вопрос команде \"ЗИКР\"").pauseFor(1000).deleteChars(14).typeString("<span style=\"background: #0077FF;\">имаму</span> мечети").start()
                        }}/>
                    </div>
                    <div className="contact-wrapper">
                        <form id="contact-form" className="contact-form-horizontal"
                              onSubmit={this.handleApplicationWithCaptcha}>
                            {this.state.applicationSuccess ?
                                <div className="contact-response-alarm contact-success">Успех
                                    <div>
                                        Номер заявки:<strong> {this.state.websiteRequestNumber} </strong>
                                    </div>
                                </div> : null}
                            {this.state.applicationError ?
                                <div className="contact-response-alarm contact-error">Что-то пошло не
                                    так<br/> Пожалуйста, попробуйте позже
                                </div> : null}
                            <label htmlFor="name" className="contact-input">
                                <input type="text" className="contact-form-control" id="name" name="name"
                                       placeholder="&nbsp;"
                                       onChange={this.onInputChange} value={this.state.name}/>
                                <span className="contact-label">Как к Вам обращаться?</span>
                                <span className="contact-focus-bg"/>
                            </label>
                            {this.state.nameError ?
                                <div className="contact-alarm">{this.state.nameError}</div> : null}
                            <label htmlFor="emailOrPhone" className="contact-input">
                                <input type="text" className="contact-form-control" id="emailOrPhone"
                                       name="emailOrPhone"
                                       placeholder="&nbsp;"
                                       onChange={this.onInputChange} value={this.state.emailOrPhone}/>
                                <span className="contact-label">Email или телефон</span>
                                <span className="contact-focus-bg"/>
                            </label>
                            {this.state.emailOrPhoneError ?
                                <div className="contact-alarm">{this.state.emailOrPhoneError}</div> : null}
                            <label htmlFor="description" className="contact-input">
                            <textarea className="contact-textarea contact-form-control" id="description"
                                      name="description"
                                      placeholder="&nbsp;"
                                      onChange={this.onInputChange} value={this.state.description}/>
                                <span className="contact-label contact-description-label">Ваш вопрос</span>
                                <span className="contact-focus-bg"/>
                            </label>
                            {this.state.descriptionError ?
                                <div className="contact-alarm ">{this.state.descriptionError}</div> : null}
                            <button type="submit" className="contact-send-button" id="submit"
                                    value="Отправить">
                                <div className="contact-alt-send-button">
                                    <i className="fa fa-paper-plane"></i><span
                                    className="contact-send-text">Отправить</span>
                                </div>
                            </button>
                        </form>
                        <hr/>
                        <div className="direct-contact-container">
                            <div className="mobileTitle">Наши контакты</div>
                            <ul className="contact-list">
                                <div onClick={() => {
                                    navigator.clipboard.writeText(this.state.mosqueAddress)
                                }}>
                                    <li className="list-item"><i className="fa fa-map-marker iconSize">
                                        <span className="contact-text contact-place">6-я лин. В.О. 35Б, Санкт-Петербург, 199004
                                            <a title="Скопировать"><i
                                                className="rightSpace far fa-copy"/></a></span></i></li>
                                </div>
                                <li className="list-item"><i className="fa fa-phone iconSize"><span
                                    className="contact-text contact-phone"><a href="tel:+79219805636"
                                                                              title="Позвонить сотрудникам мечети">+7(921)980-56-36</a></span></i>
                                </li>

                                <li className="list-item"><i className="fa fa-envelope iconSize"><span
                                    className="contact-text contact-gmail"><a href="mailto:zikr.spb@gmail.com"
                                                                              title="Написать сотрудникам мечети">zikr.spb@gmail.com</a></span></i>
                                </li>

                                <li className="list-item"><i className="far fa-clock iconSize"><span
                                    className="contact-text contact-gmail">С 10:00 до 18:00 (сб-вс выходной)</span></i>
                                </li>
                                <li className="list-item"><i className="fa fa-university iconSize"><span
                                    className="contact-text contact-gmail"><a href="/organization-details" title="Перейти к реквизитам организации">Реквизиты МРОМ</a></span></i>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="map-wrapper">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d999.1235478465767!2d30.277837757620784!3d59.94463464942086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469631257a07cea5%3A0xcaddceb58334c389!2z0JzQtdGB0YLQvdCw0Y8g0YDQtdC70LjQs9C40L7Qt9C90LDRjyDQvtGA0LPQsNC90LjQt9Cw0YbQuNGPINC80YPRgdGD0LvRjNC80LDQvSDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQs9CwICLQl9CY0JrQoCI!5e0!3m2!1sru!2sru!4v1612370140207!5m2!1sru!2sru"
                            width="100%"
                            height="450"
                            frameBorder="0"
                            style={{border: 0}} allowFullScreen="" aria-hidden="false"
                            tabIndex="0"></iframe>
                    </div>
                </section>
            </>
        );
    }
}


