import React from "react";
import './PublicOffer.scss';
import {OrganizationDetailsComponent} from "../organizationDetails/OrganizationDetailsComponent";

export function PublicOfferComponent() {
    return (
        <>
            <div className="public-offer-container public-offer-content">
                <text>
                    <h3>Публичная оферта о заключении договора пожертвования</h3>
                    <br/>
                    <strong>«Местная
                        религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>,
                    предлагает гражданам сделать пожертвование на ниже приведенных условиях:
                    <br/>
                    1. Общие положения
                    <br/>
                    1.1. В соответствии с п. 2 ст. 437 Гражданского кодекса Российской Федерации данное предложение
                    является публичной офертой (далее – Оферта).
                    <br/>
                    1.2. В настоящей Оферте употребляются термины, имеющие следующее значение:
                    «Пожертвование» - «дарение вещи или права в общеполезных целях»;
                    «Жертвователь» - «граждане, делающие пожертвования»;
                    «Получатель пожертвования» - <strong>«Местная
                    религиозная организация мусульман Санкт-Петербурга "Зикр"»</strong>.
                    <br/>
                    1.3. Оферта действует бессрочно с момента размещения ее на сайте Получателя пожертвования.
                    <br/>
                    1.4. Получатель пожертвования вправе отменить Оферту в любое время путем удаления ее со страницы
                    своего сайта в Интернете.
                    <br/>
                    1.5. Недействительность одного или нескольких условий Оферты не влечет недействительность всех
                    остальных условий Оферты.
                    <br/>
                    2. Существенные условия договора пожертвования:
                    <br/>
                    2.1. Пожертвование используется на содержание и ведение уставной деятельности Получателя
                    пожертвования.
                    <br/>
                    2.2. Сумма пожертвования определяется Жертвователем.
                    <br/>
                    3. Порядок заключения договора пожертвования:
                    <br/>
                    3.1. В соответствии с п. 3 ст. 434 Гражданского кодекса Российской Федерации договор
                    пожертвования
                    заключается в письменной форме путем акцепта Оферты Жертвователем.
                    <br/>
                    3.2. Оферта может быть акцептована путем перечисления Жертвователем денежных средств в пользу
                    Получателя пожертвования платежным поручением по реквизитам, указанным в разделе 5 Оферты, с
                    указанием в строке «назначение платежа»: «пожертвование на содержание и ведение уставной
                    деятельности», а также с использованием пластиковых карт, электронных платежных систем и других
                    средств и систем, позволяющих Жертвователю перечислять Получателю пожертвования денежных
                    средств.
                    <br/>
                    3.3. Совершение Жертвователем любого из действий, предусмотренных п. 3.2. Оферты, считается
                    акцептом
                    Оферты в соответствии с п. 3 ст. 438 Гражданского кодекса Российской Федерации.
                    <br/>
                    3.4. Датой акцепта Оферты – датой заключения договора пожертвования является дата поступления
                    пожертвования в виде денежных средств от Жертвователя на расчетный счет Получателя
                    пожертвования.
                    <br/>
                    4. Заключительные положения:
                    <br/>
                    4.1. Совершая действия, предусмотренные настоящей Офертой, Жертвователь подтверждает, что
                    ознакомлен
                    с условиями Оферты, целями деятельности Получателя пожертвования, осознает значение своих
                    действий и
                    имеет полное право на их совершение, полностью и безоговорочно принимает условия настоящей
                    Оферты.
                    <br/>
                    4.2. Настоящая Оферта регулируется и толкуется в соответствии с действующим российском
                    законодательством.
                    <br/>
                    5. Подпись и реквизиты Получателя пожертвования
                    <br/>
                    <OrganizationDetailsComponent/>
                </text>
            </div>
        </>
    );
}


