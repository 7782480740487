import React, {Component} from "react";
import './PrivacyPolicy.scss';
import {PrivacyPolicyComponent} from "./PrivacyPolicyComponent";

export class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        // this.onInputChange = this.onInputChange.bind(this);
        // this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
    }

    render() {
        return <PrivacyPolicyComponent/>
    }
}


