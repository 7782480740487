import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import './Navbar.scss'
import logoWhite from '../../../images/logoWhite.svg'
import logoDark from '../../../images/logoDark.svg'
import Dropdown from "./Dropdown";

function Navbar() {
    const [click, setClick] = useState(false);
    const [navbar, setNavbar] = useState(false)
    const [dropDown, setDropDown] = useState(false)

    const onMouseEnter = () => {
        window.innerWidth < 960 ? setDropDown(!dropDown) : setDropDown(!dropDown)
        }

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const changeNavBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    };
    window.addEventListener('scroll', changeNavBackground);

    return (
        <>
            <nav className={navbar ? 'navbar active' : 'navbar'}>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src={navbar ? logoDark : logoWhite} alt='logo'/>
                    </Link>
                    <div className={navbar ? 'menu-icon active' : 'menu-icon'} onClick={handleClick}>
                        <i className={click ? navbar ? ' fas fa-times' : ' white fas fa-times' : navbar ? 'fas fa-bars' : 'white fas fa-bars'}/>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                        <li className='nav-item'>
                            <Link to='/about-us' className={navbar ? 'nav-links active' : 'nav-links'}
                                  onClick={closeMobileMenu}>
                                О нас
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/news' className={navbar ? 'nav-links active' : 'nav-links'}
                                  onClick={closeMobileMenu}>
                                Новости
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/namaz-time' className={navbar ? 'nav-links active' : 'nav-links'}
                                  onClick={closeMobileMenu}>
                                Расписание намаза
                            </Link>
                        </li>
                        <li className='nav-item' onClick={onMouseEnter}>
                            <div className={navbar ? 'nav-links active' : 'nav-links'}>
                                При мечети
                            </div>
                            <div onClick={closeMobileMenu}>
                                {dropDown && <Dropdown/>}
                            </div>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact-us' className={navbar ? 'nav-links active' : 'nav-links'}
                                  onClick={closeMobileMenu}>
                                Контакты
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
