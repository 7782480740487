import React from "react";
import './App.css';
import Navbar from "./components/pages/navBar/Navbar";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Home} from "./components/pages/home/Home";
import ScrollToTop from "./components/ScrollToTop";
import {ContactUs} from "./components/pages/contactUs/ContactUs";
import {Volunteer} from "./components/pages/volunteer/Volunteer";
import {Footer} from "./components/pages/footer/Footer";
import SignIn from "./components/Signin";
import Register from "./components/Register";
import Admin from "./components/pages/admin/Admin";
import News from "./components/pages/news/News";
import {AboutUs} from "./components/pages/aboutUs/AboutUs";
import {LearnArabic} from "./components/pages/learnArabic/LearnArabic";
import {IngushCommunity} from "./components/pages/ingushCommunity/IngushCommunity";
import {NamazTime} from "./components/pages/namazTime/NamazTime";
import NewsList from "./components/pages/newstList/NewsList";
import ApplicationList from "./components/pages/applicationList/ApplicationList";
import {PrivacyPolicy} from "./components/pages/privacyPolicy/PrivacyPolicy";
import {PublicOffer} from "./components/pages/publicOffer/PublicOffer";
import {PaymentSecurityGuarantees} from "./components/pages/paymentSecurityGuarantees/PaymentSecurityGuarantees";
import {OrganizationDetails} from "./components/pages/organizationDetails/OrganizationDetails";
import {Donation} from "./components/pages/donation/Donation";
import YandexMetrika from "./components/pages/analytics/YandexMetrika";

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Navbar/>
            <Switch>
                <Route path='/' exact component={Home}/>
                <Route path='/news/:id' exact component={News}/>
                <Route path='/news' exact component={NewsList}/>
                <Route path='/contact-us' exact component={ContactUs}/>
                <Route path='/admin/login' exact component={SignIn}/>
                <Route path='/admin/register' exact component={Register}/>
                <Route path='/admin/home' exact component={Admin}/>
                <Route path='/about-us' exact component={AboutUs}/>
                <Route path='/volunteer' exact component={Volunteer}/>
                <Route path='/learn-arabic' exact component={LearnArabic}/>
                <Route path='/ingush-community' exact component={IngushCommunity}/>
                <Route path='/namaz-time' exact component={NamazTime}/>
                <Route path='/admin/applications' exact component={ApplicationList}/>
                <Route path='/personal-data-usage-terms' exact component={PrivacyPolicy}/>
                <Route path='/public-offer' exact component={PublicOffer}/>
                <Route path='/payment-security-guarantees' exact component={PaymentSecurityGuarantees}/>
                <Route path='/organization-details' exact component={OrganizationDetails}/>
                <Route path='/donation' exact component={Donation}/>
                {/*<Route path='/investor' exact component={ApplicationList}/>*/} {/*todo add 3 pages*/}
                {/*<Route path='/maintenance' exact component={ApplicationList}/>*/}
                {/*<Route path='/sadaqah' exact component={ApplicationList}/>*/}
            </Switch>
            <Footer/>
            <YandexMetrika/>
        </Router>
    );
}

export default App;
