import React, {Component} from "react";
import './NamazTime.scss';

export class NamazTime extends Component {
    state = {
        selectedFile: null
    }
    today = new Date();
    time = (this.today.getHours() < 10 ? '0' : '') + this.today.getHours() + ":" + (this.today.getMinutes() < 10 ? '0' : '') + this.today.getMinutes();

    constructor(props) {
        super(props);
        this.state = {
            namazTimeDay: {},
            namazTimeMonth: [{}]
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_DOMAIN_NAME}/namaz-time/day`)
            .then(res => res.json())
            .then(result => {
                this.setState({namazTimeDay: result})
            });
        fetch(`${process.env.REACT_APP_DOMAIN_NAME}/namaz-time/month`)
            .then(res => res.json())
            .then(result => {
                this.setState({namazTimeMonth: result})
            });
    }

    render() {
        return (
            <>
                <section className="today_namaz-container">

                    <div className="calendar">
                        <div className="calendar_header">
                            <h1>Время намаза</h1>
                            <p className="header_copy">в Санкт-Петербурге<div className="header_copy_title_clarification">*</div></p>
                        </div>
                        <div className="today_calendar">
                            <div className="tc_form">
                                <div className="tc_title">Сегодня {this.state.namazTimeDay.date}</div>
                            </div>
                        </div>
                        <div className="today_events">
                            <div className={this.time >= this.state.namazTimeDay.fajr &&
                            this.time <= this.state.namazTimeDay.sunrise ? 'te_item te_item_active' : 'te_item'}>
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.fajr}</div>
                                <div className="ei_Copy">Фаджр</div>
                            </div>
                            <div className="te_item">
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.sunrise}</div>
                                <div className="ei_Copy">Восход</div>
                            </div>
                            <div className={this.time >= this.state.namazTimeDay.dhuhr &&
                            this.time < this.state.namazTimeDay.asr ? 'te_item te_item_active' : 'te_item'}>
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.dhuhr}</div>
                                <div className="ei_Copy">Зухр</div>
                            </div>
                            <div className={this.time >= this.state.namazTimeDay.asr &&
                            this.time < this.state.namazTimeDay.maghrib ? 'te_item te_item_active' : 'te_item'}>
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.asr}</div>
                                <div className="ei_Copy">Аср</div>
                            </div>
                            <div className={this.time >= this.state.namazTimeDay.maghrib &&
                            this.time < this.state.namazTimeDay.isha ? 'te_item te_item_active' : 'te_item'}>
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.maghrib}</div>
                                <div className="ei_Copy">Магриб</div>
                            </div>
                            <div className={this.time >= this.state.namazTimeDay.isha ||
                            this.time < this.state.namazTimeDay.fajr ? 'te_item te_item_active' : 'te_item'}>
                                <div className="ei_Dot"/>
                                <div className="ei_Title">{this.state.namazTimeDay.isha}</div>
                                <div className="ei_Copy">Иша</div>
                            </div>
                        </div>
                        <div className="today_events_clarification">*Шафиитский мазхаб</div>
                    </div>
                </section>


                <div className="month_namaz_wrapper">
                    <div className="mn_title">Расписание на месяц</div>
                    <div className="mn_table">
                        <div className="mn_header">
                            <div className="mn_event_item">День</div>
                            <div className="mn_event_item">Фаджр</div>
                            <div className="mn_event_item">Восход</div>
                            <div className="mn_event_item">Зухр</div>
                            <div className="mn_event_item">Аср</div>
                            <div className="mn_event_item">Магриб</div>
                            <div className="mn_event_item no-border">Иша</div>
                        </div>
                        {this.state.namazTimeMonth.map((item, index) => {
                            return <div className=" mn_row" key={index}>
                                <div className="mn_data">{item.date}</div>
                                <div className="mn_data">{item.fajr}</div>
                                <div className="mn_data">{item.sunrise}</div>
                                <div className="mn_data">{item.dhuhr}</div>
                                <div className="mn_data">{item.asr}</div>
                                <div className="mn_data">{item.maghrib}</div>
                                <div className="mn_data no-border">{item.isha}</div>
                            </div>
                        })}
                    </div>
                </div>
            </>
        );
    }
}


