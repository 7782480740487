import React, {Component} from "react";
import './PaymentSecurityGuarantees.scss';
import {PaymentSecurityGuaranteesComponent} from "./PaymentSecurityGuaranteesComponent";

export class PaymentSecurityGuarantees extends Component {
    constructor(props) {
        super(props);
        // this.onInputChange = this.onInputChange.bind(this);
        // this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
    }

    render() {
        return <PaymentSecurityGuaranteesComponent/>
    }
}


