import axios from 'axios';
import authHeader from './auth-header';

const API_URL = `${process.env.REACT_APP_DOMAIN_NAME}/admin/`;

class AdminService {
    getAdminBoard() {
        return axios.get(API_URL + 'info', {headers: authHeader()});
    }

    hasPermission() {
        return axios.get(API_URL + 'hasPermission', {headers: authHeader()});
    }
}

export default new AdminService();
