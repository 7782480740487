import React, {Component} from "react";
import './PublicOffer.scss';
import {PublicOfferComponent} from "./PublicOfferComponent";

export class PublicOffer extends Component {
    constructor(props) {
        super(props);
        // this.onInputChange = this.onInputChange.bind(this);
        // this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
    }

    render() {
       return <PublicOfferComponent/>
    }
}


