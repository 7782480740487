import React, {Component} from "react";
import './LearnArabic.scss';
import ApplicationService from "../../security/application-form.service";
import learnArabicChild from './learnArabicChild.jpeg';

let phoneRegexp = "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$";
let emailRegexp = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])"

const learnArabicInitial = {
    name: "",
    emailOrPhone: "",
    description: "",
    group: "Группа - младшая группа",
    knowledge: "Уровень владения - начальный",
    skill: "UNDEFINED",
    subject: "ARABIC_LEARN",
    websiteRequestNumber: "",
    nameError: "",
    emailOrPhoneError: "",
    descriptionError: "",
    applicationSuccess: false,
    applicationError: false,

}

export class LearnArabic extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleApplicationWithCaptcha = this.handleApplicationWithCaptcha.bind(this);
        this.state = learnArabicInitial
    }

    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    handleGroupChange(event) {
        this.setState(({
                grop: event.target.value,
            }),
        )
    }

    handleKnowledgeChange(event) {
        this.setState(({
                knowledge: event.target.value,
            }),
        )
    }


    validate = () => {
        let nameError = "";
        let emailOrPhoneError = "";
        let descriptionError = "";
        if (!this.state.name.trim()) {
            nameError = "Введите Ваше имя"
        }
        if (this.state.name.trim().length < 3 && !nameError) {
            nameError = "Введите Ваше имя. Более 3 символов"
        }
        if (!(this.state.emailOrPhone.match(phoneRegexp) || this.state.emailOrPhone.match(emailRegexp))) {
            emailOrPhoneError = "Введите email или номер телефона"
        }
        if (!this.state.description.trim()) {
            descriptionError = "Введите сообщение"
        }
        if (nameError || emailOrPhoneError || descriptionError) {
            this.setState({nameError, emailOrPhoneError, descriptionError});
            return false
        }
        return true
    }


    handleApplicationWithCaptcha(e) {
        e.preventDefault()
        const isValid = this.validate()
        if (isValid) {
            ApplicationService.application(
                this.state.name,
                this.state.emailOrPhone,
                this.state.description + " " + this.state.group + " " + this.state.knowledge,
                this.state.subject,
                this.state.skill,
                "").then(
                (response) => {
                    this.setState(learnArabicInitial)
                    this.setState({
                        websiteRequestNumber: response,
                        applicationSuccess: true,
                    });
                },
                error => {
                    this.setState({
                        applicationError: true,
                    });
                });
        }
    }

    render() {
        return (
            <>
                <div className="arabic-container">
                    <img width="100%" height="90%" src={learnArabicChild} decoding="async" loading="lazy"
                         alt="Девочка пишет"/>
                    <div className="arabic-title">
                        Изучение с нуля
                        <div className="arabic-title-big"> Арабский язык</div>
                        БЕСПЛАТНО
                    </div>

                    <div className="arabic-text-area">
                        Здесь вы можете оставить заявку на изучение арабского языка.
                        <br/>
                        На данный момент <div className="arabic-schedule-link">занятия </div> для младших и старших
                        групп <div className="arabic-schedule-link"> проводятся в воскресенье</div>
                        {/*Чтобы узнать расписание занятий, можно перейти
                        на <a className="arabic-schedule-link" href="/contact-us"> расписание </a>*/}
                    </div>
                    <iframe className="arabic-video" width="100%" height="600"
                            src="https://www.youtube.com/embed/55XffVuA-o8"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className="arabic-imam-speech-container">

                        <div>
                            <p className="arabic-imam-quote">"Арабский язык – самый красноречивый из языков, самый
                                понятный и самый широкий [по способам] передачи смыслов, которые только могут возникнуть
                                в голове. Поэтому самая почтенная Книга ниспослана на самом почтенном языке, самому
                                почтенному Посланнику, через самого почтенного Ангела, в самой почтенной части земли, и
                                началось её ниспослание в самый почтенный месяц года, то есть в Рамадан"
                            </p>
                            <strong>Хафиз Ибн Касир</strong>
                            <br/>
                            исламский учёный-правовед, историк, хафиз, толкователь Корана и хадисов.

                        </div>
                    </div>
                    <hr/>
                    <div className="arabic-application-container">
                        <div className="arabic-application-article">Хочу начать учить арабский язык</div>

                        <div className="arabic-application-text">Заполните заявку. Мы направим Вам подробную информацию
                            и пришлем приглашение на почту
                        </div>
                        <form id="arabic-form" className="arabic-form-horizontal"
                              onSubmit={this.handleApplicationWithCaptcha}>
                            {this.state.applicationSuccess ?
                                <div className="arabic-response-alarm arabic-success">Успех
                                    <div>
                                        Номер заявки:<strong> {this.state.websiteRequestNumber} </strong>
                                    </div>
                                </div> : null}
                            {this.state.applicationError ?
                                <div className="arabic-response-alarm arabic-error">Что-то пошло не так
                                    <div>
                                        Пожалуйста, напишите нам на почту.
                                    </div>
                                </div> : null}
                            <label htmlFor="name" className="arabic-input">
                                <input type="text" className="arabic-form-control" id="name" name="name"
                                       placeholder="&nbsp;"
                                       onChange={this.onInputChange} value={this.state.name}/>
                                <span className="arabic-label">Как к Вам обращаться?</span>
                                <span className="arabic-focus-bg"/>
                            </label>
                            {this.state.nameError ?
                                <div className="arabic-alarm">{this.state.nameError}</div> : null}
                            <label htmlFor="emailOrPhone" className="arabic-input">
                                <input type="text" className="arabic-form-control" id="emailOrPhone"
                                       name="emailOrPhone"
                                       placeholder="&nbsp;"
                                       onChange={this.onInputChange} value={this.state.emailOrPhone}/>
                                <span className="arabic-label">Email или телефон</span>
                                <span className="arabic-focus-bg"/>
                            </label>
                            {this.state.emailOrPhoneError ?
                                <div className="arabic-alarm">{this.state.emailOrPhoneError}</div> : null}
                            <label htmlFor="groupCategory">
                                <select id="groupCategory" className="arabic-learn-select"
                                        onChange={(e) => this.handleGroupChange(e)}>
                                    <option defaultValue="Группа - младшая группа">Младшая группа</option>
                                    <option value="Группа - взрослая группа">Взрослая группа</option>
                                </select>
                            </label>

                            <label htmlFor="knowledgeCategory">
                                <select id="knowledgeCategory" className="arabic-learn-select"
                                        onChange={(e) => this.handleKnowledgeChange(e)}>
                                    <option defaultValue="Уровень владения - начальный">Начальный уровень</option>
                                    <option value="Уровень владения - продвинутый">Продвинутый уровень</option>
                                </select>
                            </label>
                            <label htmlFor="description" className="arabic-input">
                            <textarea className="arabic-textarea arabic-form-control" id="description"
                                      name="description"
                                      placeholder="&nbsp;"
                                      onChange={this.onInputChange} value={this.state.description}/>
                                <span className="arabic-label arabic-description-label">О себе</span>
                                <span className="arabic-focus-bg"/>
                            </label>
                            {this.state.descriptionError ?
                                <div className="arabic-alarm ">{this.state.descriptionError}</div> : null}
                            <button type="submit" className="arabic-send-button" id="submit"
                                    value="Отправить">
                                <div className="arabic-alt-send-button">
                                    <i className="fa fa-paper-plane"/><span
                                    className="arabic-send-text">Отправить</span>
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}


