import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.REACT_APP_DOMAIN_NAME}/admin/`;

class AuthService {
    login(username, password) {
        return axios
            .post(API_URL + "signin", {
                username,
                password
            })
            .then(response => {
                localStorage.setItem("user", JSON.stringify(response.data));
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(data) {
        return axios.post(API_URL + "register", data, {headers: authHeader()});
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();
