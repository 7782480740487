import React, {Component} from "react";
import './News.scss';

export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: {},
            user: {}
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_DOMAIN_NAME}${this.props.match.url}`)
            .then(res => res.json())
            .then(result => {
                this.setState({news: result})
                this.setState({user: result.user})
            });
    }

    render() {
        return (
            <>
                <div className="news-container">
                    <div className="news-flex-div mg-top">
                        <p className="news-heading-date-created">{this.state.news.heading},<span
                            className="news-date-created"> {this.state.news.date}  </span></p></div>
                    <h1 className="news-title mg-top">{this.state.news.subject}</h1>

                    <img alt="" className="news-main-image mg-top" decoding="async" loading="lazy"
                         src={'/' + this.state.news.imagePath}
                         width="638" height="359" role="presentation"/>

                    <p className="news-description">{this.state.news.text}</p>

                    <div className="news-author">Автор</div>
                    <div className="news-author-wrapper">
                        <img alt="Автор новости" className="bordRadius"
                         src={'/' + this.state.user.imagePath} width="28"
                         height="28"/>
                        <span className="news-creator-name">{this.state.user.fio}</span>
                    </div>
                </div>

            </>
        );
    }
}
