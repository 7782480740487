import React, {Component} from "react";
import './Donation.scss';

const initialState = {
    fullName: "",
    email: "",
    amount: "100",
    personalDataUsageTerms: true,
    publicOffer: true,
}

export class Donation extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.state = initialState
    }


    validateForm = () => {
        const {email, amount, fullName} = this.state;
        if (email === "" || email === " ") {
            this.state.email = "donation@zikr.ru"
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Простая регулярка для проверки email
            if (!emailRegex.test(email)) {
                alert("Введите правильный электронный адрес.");
                return false;
            }
        }

        if (amount < 50) {
            alert("Сумма должна быть не меньше 50 рублей.");
            return false;
        }

        if (fullName === "" || fullName === " ") {
            this.state.fullName = "anonymous"
        }
        return true;
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (!this.validateForm()) {
            return;
        }

        const {fullName, email, amount} = this.state;

        try {
            const response = await fetch('https://api.zikr-spb.ru/payment/generate-url', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: fullName,
                    email: email,
                    amount: amount
                })
            });

            const data = await response.text();
            if (data) {
                window.location.href = data; // Редирект на URL, полученный в ответе
            }
        } catch (error) {
            console.error('Ошибка при отправке формы:', error);
        }
    }


    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePersonalDataUsageTerms = () => {
        this.setState({personalDataUsageTerms: !this.state.personalDataUsageTerms});
    };

    handlePublicOffer = () => {
        this.setState({publicOffer: !this.state.publicOffer});
    };


    render() {
        return (
            <>
                <div className="donation-main-wrapper">
                    <div className="donation-form-wrapper">
                        <h1>Отправить средства в
                            <br/>
                            МРОМ "Зикр"
                        </h1>
                        <form>

                            <div className="donation-steps"/>

                            <div>
                                <div>
                                    <div>
                                        <label htmlFor="fullName" className="donation-form-label"> Имя и Фамилия</label>
                                        <input
                                            type="text"
                                            name="fullName"
                                            placeholder="Андрей Иванов"
                                            id="fullName"
                                            onChange={this.onInputChange} value={this.state.name}
                                            className="donation-form-input"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <label htmlFor="email" className="donation-form-label"> Электронная
                                            почта </label>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="example@mail.ru"
                                            id="email"
                                            className="donation-form-input"
                                            onChange={this.onInputChange} value={this.state.email}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="amount" className="donation-form-label"> Сумма</label>
                                    <input
                                        name="amount"
                                        id="amount"
                                        onChange={this.onInputChange} value={this.state.amount}
                                        className="donation-form-input"
                                    />
                                </div>
                            </div>
                            <div className="donation-steps">
                                <ul>
                                    <input id="personal-data-usage-terms" className="donation-checkbox" type="checkbox"
                                           name="personal-data-usage-terms" value={this.state.personalDataUsageTerms}
                                           checked={this.state.personalDataUsageTerms}
                                           onChange={this.handlePersonalDataUsageTerms}/>
                                    <label htmlFor="personal-data-usage-terms" className="donation-checkbox-text">
                                        <a href="/personal-data-usage-terms" className="donation-link">
                                            Согласие на обработку персональных данных </a>
                                    </label>
                                    <br/>
                                    <input id="public-offer" className="donation-checkbox" type="checkbox" name="site"
                                           value={this.state.publicOffer} checked={this.state.publicOffer}
                                           onChange={this.handlePublicOffer}/>
                                    <label htmlFor="public-offer" className="donation-checkbox-text">
                                        <a href="/public-offer" className="donation-link">
                                            Публичная оферта о заключении договора пожертвования </a>
                                    </label>

                                </ul>
                            </div>
                            <div className="donation-form-btn-wrapper">
                                <button className="donation-btn" onClick={this.handleSubmit}>
                                    Перейти к оплате
                                </button>
                            </div>
                        </form>
                        <br/>
                        <div className="donation-steps">
                            Вы так же можете ознакомиться с:

                            <ul>
                                <li>
                                    <a href="/organization-details" className="donation-link">
                                        Реквизитами МРОМ "Зикр"</a>
                                </li>

                                <li>
                                    <a href="/payment-security-guarantees" className="donation-link">
                                        Гарантиями безопасности платежа</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


