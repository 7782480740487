import React, {Component} from "react";

import './AboutUs.scss';
import main_room from './main_room.jpeg'
import room_video from './room_video.mp4'


export class AboutUs extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            document.getElementById("about-us-video").style.display = "none";
            let containerElement = document.getElementById("about-us-video");
            let iframe_tag = containerElement.querySelector('iframe');
            let video_tag = containerElement.querySelector('video');
            if (iframe_tag) {
                /* without this code it does not work */
                let iframeSrc = iframe_tag.src;
                iframe_tag.src = iframeSrc;
            }
            if (video_tag) {
                video_tag.pause();
            }
        }
    }

    changeColor = () => {
        document.getElementById("about-us-video").style.display = "flex";
    }

    render() {
        return (
            <>
                <div className="about-us-container">
                    <div className="about-us-title">
                        <div className="about-us-title-main">Почему мы</div>
                        <div className="about-us-subtitle"><strong className="about-us-background-blue">больше</strong>,
                            чем просто МРОМ
                        </div>

                        {/*  <button className="about-us-button" onClick={this.changeColor}><i
                            className="far fa-play-circle fa-2xl about-us-play-icon"/></button>*/}
                    </div>
                    <div ref={this.wrapperRef}>
                        <div id="about-us-video" className="about-us-video-player">
                            <iframe className="about-us-youtube-video" width="100%" height="600"
                                    src="https://www.youtube.com/embed/gYpoUJAfk1Q?start=9"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                    </div>
                    <div>
                        <div className="about-us-section">
                            <div className="about-us-section-container">
                                <div className="about-us-row about-us-align-items-center">

                                    <div className="about-us-text-column">
                                        <div className="about-us-body-title">МРОМ имеет большое значение</div>
                                        <div className="about-us-body-text">Основан в 2007 году.
                                            Занимая ~300 квадратных метров, здание МРОМ состоит из центральной зоны для молитв,
                                            аудитории, конференц-залов и современных удобств для омовения.
                                            Данное место служит как религиозный, так и образовательный центр для мусульман и немусульман в г. Санкт-Петербург
                                        </div>
                                    </div>
                                    <div className="about-us-icon-column">
                                        <img className="about-us-image" decoding="async" loading="lazy"
                                             src={main_room}
                                             alt="Имам мечети"/>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="about-us-section-container">
                                <div className="about-us-row about-us-align-items-center">
                                    <div className="about-us-text-column">
                                        <div className="about-us-body-title">Цель МРОМ</div>
                                        <div className="about-us-body-text">Главная цель состоит в <strong className="about-us-strong-color">распространении истинного
                                            значения и понимания ислама как религии мира, терпимости и сосуществования. </strong>
                                            Следовательно, рамки всех аспектов нашей работы проистекают из этой точки зрения.
                                        </div>
                                        <div className="about-us-row">
                                            <div className="about-us-icon-column">
                                                <a href="/news"
                                                   className="about-us-flex-with-no-decoration about-us-align-items-center">
                                                    <div className="about-us-wrap-icon">
                                                        <i className="far fa-newspaper about-us-fas-icon"/>
                                                    </div>
                                                    <div className="about-us-icon-description">
                                                        Новости МРОМ
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="about-us-icon-column">
                                                <a href="/namaz-time"
                                                   className="about-us-flex-with-no-decoration about-us-align-items-center">
                                                    <div className="about-us-wrap-icon">
                                                        <i className="fas fa-clock about-us-fas-icon"/>
                                                    </div>
                                                    <div className="about-us-icon-description">
                                                        Расписание намаза
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="about-us-icon-column">
                                                <a href="/learn-arabic"
                                                   className="about-us-flex-with-no-decoration about-us-align-items-center">
                                                    <div className="about-us-wrap-icon">
                                                        <i className="fas fa-quran about-us-fas-icon"/>
                                                    </div>
                                                    <div className="about-us-icon-description">
                                                        Изучать арабский
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="about-us-icon-column">
                                                <a href="/contact-us"
                                                   className="about-us-flex-with-no-decoration about-us-align-items-center">
                                                    <div className="about-us-wrap-icon">
                                                        <i className="fas fa-map-marked-alt about-us-fas-icon"/>
                                                    </div>
                                                    <div className={"about-us-icon-description"}>
                                                        Где найти нас
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about-us-icon-column">
                                        <video className="about-us-video" autoPlay loop muted>
                                            <source src={room_video} type='video/mp4'/>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


