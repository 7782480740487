import React, {Component} from "react";
import axios from "axios";
import authHeader from "../../security/auth-header";
import './Admin.css';
import AdminService from "../../security/admin.service";
import {Link} from "react-router-dom";

const initialState = {
    instagram: false,
    site: false,
    text: "",
    subject: "",
    heading: "WE",
    content: "null",
    multipartImage: null,
    checkBoxError: "",
    subjectError: "",
    textError: "",
    imageError: "",
}

export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.onInputChange = this.onInputChange.bind(this);
        this.state = initialState;
    }

    validate = () => {
        let subjectError = "";
        let textError = "";
        let checkBoxError = "";
        let imageError = "";
        if (!this.state.subject.trim()) {
            subjectError = "Введите заголовок"
        }
        if (this.state.subject.length > 80) {
            subjectError = "Максимальное число символов заголовка 80"
        }
        if (!this.state.text.trim()) {
            textError = "Введите текст"
        }
        if (!this.state.site && !this.state.instagram) {
            checkBoxError = "Выберите, где опубликовать новость"
        }

        if (this.state.multipartImage && this.state.multipartImage.size > 5000000) {
            imageError = "Размер файла превышает 5 мб"
        }
        if (subjectError || textError || checkBoxError || imageError) {
            this.setState({subjectError, textError, checkBoxError, imageError});
            return false
        }
        return true
    }

    handleChange(event) {
        this.setState(({
                heading: event.target.value,
            }),
        )
    }

    onInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount() {
        AdminService.getAdminBoard().then(
            response => {
                this.setState({

                    content: response.data
                });
            },
            error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    this.props.history.push("/");
                    window.location.reload();
                }
            }
        );
    }

    fileSelectedHandler = event => {
        this.setState({
                selectedFile: event.target.files[0]
            }
        )
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const isValid = this.validate()
        if (isValid) {
            let data = new FormData();
            data.append("multipartImage", this.state.multipartImage);
            data.set("instagram", this.state.instagram);
            data.set("site", this.state.site);
            data.set("content", this.state.content);
            data.set("text", this.state.text);
            data.set("subject", this.state.subject);
            data.set("heading", this.state.heading);

            axios
                .post(`${process.env.REACT_APP_DOMAIN_NAME}/news`, data, {headers: authHeader()})
                .then(response => {
                        if (response.status === 200) {
                            window.location = '/news/' + response.data.id
                        }
                    }
                )
                .catch();
        }
    };

    handleImage = (event) => {
        this.setState({multipartImage: event.target.files[0]});
    };

    handleSite = () => {
        this.setState({site: !this.state.site});
    };

    handleInstagram = () => {
        this.setState({instagram: !this.state.instagram});
    };

    render() {
        return (
            <>
                <div className="admin">
                    <div className='profile'>
                        <h1>Здравствуйте, {this.state.content.fio}!</h1>
                        {/*<p>{this.state.content.id}</p>*/}
                        <p>Логин: {this.state.content.username}</p>
                        <p>Роль: {this.state.content.role}</p>
                    </div>
                    <div className="actions">
                        <form className="create-news" onSubmit={this.handleSubmit}>
                            <label htmlFor="heading">Тема</label>
                            <select id="heading" className="create-news-subject"
                                    onChange={(e) => this.handleChange(e)}>
                                <option defaultValue="WE">Мы</option>
                                <option value="IMPORTANT">Важно</option>
                                <option value="IMAM">Имам</option>
                                <option value="HOLIDAY">Праздник</option>
                            </select>
                            <label htmlFor="subject"/>
                            <input type="text" id="subject" className="create-news-subject" name="subject"
                                   placeholder="Заголовок" value={this.state.subject}
                                   onChange={this.onInputChange}/>
                            {this.state.subjectError ?
                                <div style={{color: "red"}}>{this.state.subjectError}</div> : null}

                            <textarea className="create-news-text" name="text" placeholder="Ваше сообщение..."
                                      value={this.state.text} onChange={this.onInputChange}/>
                            <div>
                                {this.state.textError ?
                                    <div style={{color: "red"}}>{this.state.textError}</div> : null}


                                <input style={{display: 'none'}}
                                       type='file'
                                       onChange={this.fileSelectedHandler}
                                       ref={fileInput => this.fileInput = fileInput}/>
                                <input type='file' name="multipartImage" onChange={this.handleImage}/>
                                {this.state.imageError ?
                                    <div style={{color: "red"}}>{this.state.imageError}</div> : null}


                            </div>
                            <p>
                                Опубликовать в:
                            </p>
                            <div>
                                <input className="admin-checkbox" type="checkbox" name="site" value={this.state.site}
                                       onChange={this.handleSite}/>
                                <label className="admin-checkbox-text">Сайт</label>
                            </div>

                            <div>
                                <input className="admin-checkbox" type="checkbox" name="instagram"
                                       value={this.state.instagram} onChange={this.handleInstagram}/>
                                <label className="admin-checkbox-text">Instagram</label>
                            </div>
                            {this.state.checkBoxError ?
                                <div style={{color: "red"}}>{this.state.checkBoxError}</div> : null}

                            <input type="submit" value="Опубликовать"/>
                        </form>
                    </div>
                    <li className='nav-item'>
                        <Link to='/admin/applications'>
                            Перейти к заявкам
                        </Link>
                    </li>
                </div>
                <div className="clr"/>
            </>

        )
    }
}
