import React from "react";
import './NewsList.scss'
import NewsListTitle from "./NewsListTitle";
import NewsListContent from "./NewsListContent";


export default class NewsList extends React.Component {

    render() {
        return (
            <>
                <NewsListTitle value={'Лента новостей'}/>
                <NewsListContent/>
            </>
        )
    }
}

